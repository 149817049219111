@import './consts';
.udesk-cem-transfer-list {
    .udesk-cem-transfer-list-body {
        .udesk-cem-transfer-list-content {
            > div {
                .udesk-cem-transfer-list-content-item {
                    &:hover {
                        background: fade-out($primary-color, 0.95);
                    }
                }
            }
        }
    }
}
