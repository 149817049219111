.flay-away-box {
    .page-content {
        display: flex;
        align-items: center;
        justify-content: center;
        .flay-away-icon {
            text-align: center;
            .anticon {
                font-size: 120px;
            }
        }
        .flay-away-text {
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.25);
            line-height: 24px;
        }
    }
}
