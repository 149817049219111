@import './consts';
.udesk-cem-dropdown {
    .udesk-cem-dropdown-menu {
        .udesk-cem-dropdown-menu-item {
            &:hover {
                background-color: fade-out($primary-color, 0.95);
            }
        }
    }
}
