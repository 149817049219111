@import './consts';
.udesk-cem-calendar-picker-container {
    .udesk-cem-calendar {
        .udesk-cem-calendar-panel {
            .udesk-cem-calendar-date-panel {
                .udesk-cem-calendar-body {
                    .udesk-cem-calendar-table {
                        .udesk-cem-calendar-tbody {
                            // .udesk-cem-calendar-date {
                            //     background: $primary-color;
                            // }
                            > tr {
                                .udesk-cem-calendar-cell {
                                    &::before {
                                        background: fade-out($primary-color, 0.9);
                                    }
                                    .udesk-cem-calendar-date {
                                        &:hover {
                                            background: fade-out($primary-color, 0.9);
                                            color: rgba(0, 0, 0, 0.65);
                                        }
                                    }
                                }

                                .udesk-cem-calendar-selected-date,
                                .udesk-cem-calendar-selected-start-date,
                                .udesk-cem-calendar-selected-end-date {
                                    .udesk-cem-calendar-date {
                                        background: $primary-color;
                                    }
                                }
                                .udesk-cem-calendar-today {
                                    .udesk-cem-calendar-date {
                                        color: $primary-color;
                                        border-color: $primary-color;
                                        background: #fff;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .udesk-cem-calendar-footer {
                .udesk-cem-calendar-footer-btn {
                    .udesk-cem-calendar-footer-extra {
                        .udesk-cem-tag-blue {
                            color: $primary-color;
                            background: fade-out($primary-color, 0.95);
                            border-color: $primary-color;
                            &:hover {
                                color: fade-out($primary-color, 0.2);
                                background: fade-out($primary-color, 0.95);
                                border-color: fade-out($primary-color, 0.2);
                            }
                        }
                    }
                }
            }
        }
    }
}
.udesk-cem-calendar-picker {
    &:focus {
        .udesk-cem-calendar-picker-input:not(.udesk-cem-input-disabled) {
            border-color: $primary-color;
            border-right-width: 1px !important;
            outline: 0;
            box-shadow: 0 0 0 2px fade-out($primary-color, 0.8);
        }
    }
}
