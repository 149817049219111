.page-container {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 8px 20px 0 20px;
    background-color: #fff;
    .page-header {
        position: relative;
        height: 59px;

        width: 100%;
    }

    .udesk-cem-divider-horizontal {
        margin: 0;
    }

    .page-content {
        position: relative;
        width: 100%;
        height: 100%;
        .content-tabs-box {
            .udesk-cem-tabs-top-bar {
                padding: 0;
                .udesk-cem-tabs-tab {
                    padding: 0px 16px 8px;
                }
            }
        }

        &.show-header-and-divider {
            height: calc(100% - 76px);
        }

        &.show-header {
            height: calc(100% - 59px);
        }

        &.show-divider {
            height: calc(100% - 17px);
        }
    }
}
