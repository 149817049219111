@import '../../styles/consts';
.sub-menu-box,
.udesk-cem-menu-sub {
    .udesk-cem-menu-item {
        &:active {
            background: fade-out($primary-color, 0.95);
        }
        &:hover {
            color: $primary-color;
            > a {
                color: $primary-color;
            }
        }
    }
    .udesk-cem-menu-item-selected {
        background-color: fade-out($primary-color, 0.95) !important;
        color: $primary-color;
        > a {
            color: $primary-color;
        }
        &:after {
            border-color: $primary-color;
        }
    }
}
.udesk-cem-menu-submenu {
    .udesk-cem-menu-submenu-title {
        &:hover {
            color: $primary-color;
            .udesk-cem-menu-submenu-arrow {
                &:before {
                    background: linear-gradient(to right, $primary-color, $primary-color);
                }
            }
        }
    }
}
.udesk-cem-menu-submenu-selected {
    .udesk-cem-menu-submenu-title {
        color: $primary-color;
    }
}

.moban {
    cursor: pointer;
    &:hover {
        color: $primary-color;
    }
    .moban-title {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        // color: rgba(0, 0, 0, 0.85);
        line-height: 20px;
    }
}

.udesk-ccpass-component-button {
    background-color: #597ef7 !important;
    border-color: #597ef7 !important;
}
