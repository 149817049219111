#root {
    & > .udesk-cem-layout {
        min-height: 100vh;
        max-height: 100vh;
        background-color: transparent;
        & > .udesk-cem-layout-sider {
            width: 180px;
            background-color: #20273f;
            .icon {
                font-size: 18px;
            }

            .udesk-cem-layout-sider-children > .udesk-cem-menu {
                height: calc(100% - 56px);
            }

            .udesk-cem-menu {
                border-right: transparent;
                background-color: transparent;
                position: relative;
                overflow-y: auto;
                overflow-x: hidden;
            }
            .udesk-cem-menu-item,
            .udesk-cem-menu-submenu {
                width: 100%;
                line-height: 42px;
                color: rgba(255, 255, 255, 0.6);
                border-left: 3px solid transparent;
                & > a,
                & > .udesk-cem-menu-submenu-title > .root-menu-submenu-item {
                    color: inherit;
                    margin-left: -2px;
                    & > span {
                        padding-left: 8px;
                    }
                }
            }
            .udesk-cem-menu-item,
            .udesk-cem-menu-submenu > .udesk-cem-menu-submenu-title {
                height: 42px;

                .udesk-cem-menu-submenu-arrow::before,
                .udesk-cem-menu-submenu-arrow::after {
                    background-color: rgba(255, 255, 255, 0.6);
                    background-image: none;
                }
            }
            .udesk-cem-menu-submenu {
                margin: 4px 0 8px 0;
            }
            .udesk-cem-menu-item-selected {
                color: rgba(255, 255, 255, 0.6);
                background-color: #273256;
                border-left-color: #597ef7;
                &:after {
                    border-right: 0;
                }
            }
            .udesk-cem-menu-inline-collapsed {
                width: 100%;
                & > .udesk-cem-menu-item,
                & > .udesk-cem-menu-submenu > .udesk-cem-menu-submenu-title {
                    padding: 0 28px !important;
                    & > .root-menu-submenu-item {
                        margin-left: -13px;
                        & > span {
                            display: none;
                        }
                    }
                }
            }
        }
        & > .udesk-cem-layout {
            flex-direction: column;
            & > .udesk-cem-layout-header {
                padding: 0;
                height: 60px;
                line-height: 60px;
                z-index: 2;
                background-color: #fff;
                box-shadow: 0px 3px 5px 0px rgba(45, 89, 89, 0.08), 0px 1px 2px 0px rgba(71, 102, 102, 0.04),
                    0px -1px 0px 0px rgba(240, 240, 240, 1);
            }
            & > .udesk-cem-layout-content {
                position: relative;
                display: flex;
                flex-direction: column;
                height: 100%;
                // .udesk-cem-drawer-content-wrapper{
                //     border-top: 1px solid #eee;
                //     box-shadow: -2px 8px 8px rgba(0, 0, 0, 0.15);
                // }
            }
        }
    }
    &.fullscreen {
        & > .udesk-cem-layout {
            & > .udesk-cem-layout-sider {
                display: none;
            }
            & > .udesk-cem-layout {
                & > .udesk-cem-layout-header {
                    display: none;
                }
            }
        }
    }
}
