@import './consts';
.udesk-cem-btn-link {
    border: none;
    display: inline-block;
    padding: 0;
    color: $primary-color;
    &:hover {
        color: fade-out($primary-color, 0.3);
    }
}
