// html {
//     min-width: 1200px;
//     overflow-x: auto;
// }

a {
    color: $link-color;
    &:hover,
    &:focus {
        color: rgba($link-color, 0.7);
    }
    &.icon {
        color: inherit;
        &:hover,
        &:focus {
            color: $link-color;
        }
    }
}

ul {
    list-style: none;
    padding-left: 0;
}

.udesk-cem-btn {
    padding-top: 0;
    padding-bottom: 0;
}

.udesk-cem-tabs-nav .udesk-cem-tabs-tab {
    padding-left: 16px;
    padding-right: 16px;
}

.udesk-cem-page-header-footer .udesk-cem-tabs-bar .udesk-cem-tabs-nav .udesk-cem-tabs-tab {
    padding: 8px;
}

.udesk-cem-tabs-tabpane-active {
    outline: none;
}

.udesk-cem-input,
.udesk-cem-btn,
.udesk-cem-btn-group {
    border-radius: 3px;
}
.udesk-cem-btn-circle {
    min-width: 18px !important;
}

.udesk-cem-tooltip {
    .icon {
        display: none;
    }
}

.udesk-cem-layout-content {
    overflow-x: auto;
}

.udesk-cem-table-column-sorters {
    justify-content: center;
}

.udesk-cem-legacy-form-item-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

h1,
h2,
h3,
h4 {
    &.udesk-cem-typography {
        font-size: 16px;
        font-weight: 500 !important;
    }
}
.udesk-cem-btn {
    &:hover,
    &:focus {
        color: $primary-color;
        border-color: $primary-color;
    }
}
.udesk-cem-btn-primary {
    border-color: $primary-color;
    background-color: $primary-color;
    &:hover,
    &:focus {
        color: #fff;
        border-color: rgba($primary-color, 0.8);
        background-color: rgba($primary-color, 0.8);
    }
}

.udesk-cem-divider-horizontal {
    min-height: 1px;
}

.udesk-cem-page-header {
    font-size: 18px;
    padding-left: 0;
    padding-right: 0;
    .udesk-cem-page-header-title-view-title {
        font-weight: 500;
    }
    .udesk-cem-page-header-title-view-extra {
        right: 0;
    }
}

.udesk-cem-table {
    width: 100%;
    overflow-y: auto;
    .udesk-cem-table-body {
        & > table {
            min-width: 100%;
            width: max-content;
        }
    }
}

.udesk-cem-table-pagination {
    width: 100%;
    text-align: right;
    & .udesk-cem-pagination-total-text {
        float: left;
        color: #808080;
        font-size: 13px;
    }
}

.udesk-cem-select-tree-switcher {
    vertical-align: top;
}

#edui_fixedlayer {
    .edui-popup.edui-for-backcolor,
    .edui-popup.edui-for-forecolor {
        .edui-colorpicker-topbar {
            .edui-colorpicker-nocolor {
                height: auto;
            }
        }
    }
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #b5b1b1;
}
@-webkit-keyframes transferHighlightIn {
    0% {
        background: fade-out($primary-color, 0.95);
    }
    100% {
        background: transparent;
    }
}
@keyframes transferHighlightIn {
    0% {
        background: fade-out($primary-color, 0.95);
    }
    100% {
        background: transparent;
    }
}

.udesk-cem-picker-footer {
    width: auto;
}

// 单行文本超出部分省略号
.overflowEllipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
