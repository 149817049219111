@import './consts';
.udesk-cem-table-wrapper {
    .udesk-cem-spin-nested-loading {
        .udesk-cem-spin-container {
            .udesk-cem-table {
                .udesk-cem-table-content {
                    .udesk-cem-table-body {
                        .udesk-cem-table-tbody {
                            > tr {
                                &:hover {
                                    > td {
                                        background: fade-out($primary-color, 0.95);
                                    }
                                }
                            }
                            .udesk-cem-table-row-hover {
                                > td {
                                    background: fade-out($primary-color, 0.95);
                                }
                            }
                        }
                    }
                    .udesk-cem-table-scroll,
                    .udesk-cem-table-fixed-left,
                    .udesk-cem-table-fixed-right {
                        .udesk-cem-table-header {
                            .udesk-cem-table-fixed {
                                .udesk-cem-table-thead {
                                    > tr > th {
                                        padding: 10px 16px;
                                        .udesk-cem-table-column-sorter {
                                            .udesk-cem-table-column-sorter-inner-full {
                                                .on {
                                                    color: $primary-color;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .udesk-cem-table-body {
                            .udesk-cem-table-fixed {
                                .udesk-cem-table-tbody {
                                    > tr {
                                        &:hover {
                                            > td {
                                                background: fade-out($primary-color, 0.95);
                                            }
                                        }
                                    }
                                    .udesk-cem-table-row-hover {
                                        > td {
                                            background: fade-out($primary-color, 0.95);
                                        }
                                    }
                                }
                            }
                        }
                        .udesk-cem-table-body-outer {
                            .udesk-cem-table-body-inner {
                                .udesk-cem-table-fixed {
                                    .udesk-cem-table-tbody {
                                        > tr {
                                            &:hover {
                                                > td {
                                                    background: fade-out($primary-color, 0.95);
                                                }
                                            }
                                        }
                                        .udesk-cem-table-row-hover {
                                            > td {
                                                background: fade-out($primary-color, 0.95);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
