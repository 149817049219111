@import './consts';
.udesk-cem-tabs {
    .udesk-cem-tabs-bar {
        .udesk-cem-tabs-nav-container {
            .udesk-cem-tabs-nav-wrap {
                .udesk-cem-tabs-nav-scroll {
                    .udesk-cem-tabs-nav {
                        .udesk-cem-tabs-tab {
                            &:hover {
                                color: $primary-color;
                            }
                        }
                        .udesk-cem-tabs-tab-active {
                            color: $primary-color;
                        }
                        .udesk-cem-tabs-ink-bar {
                            background-color: $primary-color;
                        }
                    }
                }
            }
        }
    }
}
