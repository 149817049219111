/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.udesk-cem-legacy-form {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
}
.udesk-cem-legacy-form legend {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    padding: 0;
    color: rgba(0, 0, 0, 0.45);
    font-size: 16px;
    line-height: inherit;
    border: 0;
    border-bottom: 1px solid #d9d9d9;
}
.udesk-cem-legacy-form label {
    font-size: 14px;
}
.udesk-cem-legacy-form input[type='search'] {
    box-sizing: border-box;
}
.udesk-cem-legacy-form input[type='radio'],
.udesk-cem-legacy-form input[type='checkbox'] {
    line-height: normal;
}
.udesk-cem-legacy-form input[type='file'] {
    display: block;
}
.udesk-cem-legacy-form input[type='range'] {
    display: block;
    width: 100%;
}
.udesk-cem-legacy-form select[multiple],
.udesk-cem-legacy-form select[size] {
    height: auto;
}
.udesk-cem-legacy-form input[type='file']:focus,
.udesk-cem-legacy-form input[type='radio']:focus,
.udesk-cem-legacy-form input[type='checkbox']:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}
.udesk-cem-legacy-form output {
    display: block;
    padding-top: 15px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5715;
}
.udesk-cem-legacy-form-item-required::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}
.udesk-cem-legacy-form-hide-required-mark .udesk-cem-legacy-form-item-required::before {
    display: none;
}
.udesk-cem-legacy-form-item-label > label {
    color: rgba(0, 0, 0, 0.85);
}
.udesk-cem-legacy-form-item-label > label::after {
    content: ':';
    position: relative;
    top: -0.5px;
    margin: 0 8px 0 2px;
}
.udesk-cem-legacy-form-item-label > label.udesk-cem-legacy-form-item-no-colon::after {
    content: ' ';
}
.udesk-cem-legacy-form-item {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    margin-bottom: 24px;
    vertical-align: top;
}
.udesk-cem-legacy-form-item label {
    position: relative;
}
.udesk-cem-legacy-form-item label > .anticon {
    font-size: 14px;
    vertical-align: top;
}
.udesk-cem-legacy-form-item-control {
    position: relative;
    line-height: 40px;
}
.udesk-cem-legacy-form-item-control::before {
    display: table;
    content: '';
}
.udesk-cem-legacy-form-item-control::after {
    display: table;
    clear: both;
    content: '';
}
.udesk-cem-legacy-form-item-children {
    position: relative;
}
.udesk-cem-legacy-form-item-with-help {
    margin-bottom: 4px;
}
.udesk-cem-legacy-form-item-label {
    display: inline-block;
    overflow: hidden;
    line-height: 39.9999px;
    white-space: nowrap;
    text-align: right;
    vertical-align: middle;
    flex-grow: 0;
}
.udesk-cem-legacy-form-item-label-left {
    text-align: left;
}
.udesk-cem-legacy-form-item-control-wrapper {
    flex: 1 1 0;
}
.udesk-cem-legacy-form-item .udesk-cem-switch {
    margin: 2px 0 4px;
}
.udesk-cem-legacy-form-explain,
.udesk-cem-legacy-form-extra {
    clear: both;
    min-height: 23px;
    margin-top: -2px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 1.5715;
    transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.udesk-cem-legacy-form-explain {
    margin-bottom: -1px;
}
.udesk-cem-legacy-form-extra {
    padding-top: 4px;
}
.udesk-cem-legacy-form-text {
    display: inline-block;
    padding-right: 8px;
}
.udesk-cem-legacy-form-split {
    display: block;
    text-align: center;
}
form .has-feedback .udesk-cem-input {
    padding-right: 30px;
}
form .has-feedback .udesk-cem-input-affix-wrapper .udesk-cem-input-suffix {
    padding-right: 18px;
}
form .has-feedback .udesk-cem-input-affix-wrapper .udesk-cem-input {
    padding-right: 49px;
}
form .has-feedback .udesk-cem-input-affix-wrapper.udesk-cem-input-affix-wrapper-input-with-clear-btn .udesk-cem-input {
    padding-right: 68px;
}
form .has-feedback > .udesk-cem-select .udesk-cem-select-arrow,
form .has-feedback > .udesk-cem-select .udesk-cem-select-selection__clear,
form .has-feedback :not(.udesk-cem-input-group-addon) > .udesk-cem-select .udesk-cem-select-arrow,
form .has-feedback :not(.udesk-cem-input-group-addon) > .udesk-cem-select .udesk-cem-select-selection__clear {
    right: 28px;
}
form .has-feedback > .udesk-cem-select .udesk-cem-select-selection-selected-value,
form .has-feedback :not(.udesk-cem-input-group-addon) > .udesk-cem-select .udesk-cem-select-selection-selected-value {
    padding-right: 42px;
}
form .has-feedback .udesk-cem-cascader-picker-arrow {
    margin-right: 17px;
}
form .has-feedback .udesk-cem-cascader-picker-clear {
    right: 28px;
}
form .has-feedback .udesk-cem-input-search:not(.udesk-cem-input-search-enter-button) .udesk-cem-input-suffix {
    right: 28px;
}
form .has-feedback .udesk-cem-calendar-picker-icon,
form .has-feedback .udesk-cem-time-picker-icon,
form .has-feedback .udesk-cem-calendar-picker-clear,
form .has-feedback .udesk-cem-time-picker-clear {
    right: 28px;
}
form .has-feedback .udesk-cem-picker .udesk-cem-picker-suffix {
    padding-right: 17px;
}
form .udesk-cem-mentions,
form textarea.udesk-cem-input {
    height: auto;
    margin-bottom: 4px;
}
form .udesk-cem-upload {
    background: transparent;
}
form input[type='radio'],
form input[type='checkbox'] {
    width: 14px;
    height: 14px;
}
form .udesk-cem-radio-inline,
form .udesk-cem-checkbox-inline {
    display: inline-block;
    margin-left: 8px;
    font-weight: normal;
    vertical-align: middle;
    cursor: pointer;
}
form .udesk-cem-radio-inline:first-child,
form .udesk-cem-checkbox-inline:first-child {
    margin-left: 0;
}
form .udesk-cem-checkbox-vertical,
form .udesk-cem-radio-vertical {
    display: block;
}
form .udesk-cem-checkbox-vertical + .udesk-cem-checkbox-vertical,
form .udesk-cem-radio-vertical + .udesk-cem-radio-vertical {
    margin-left: 0;
}
form .udesk-cem-input-number + .udesk-cem-legacy-form-text {
    margin-left: 8px;
}
form .udesk-cem-input-number-handler-wrap {
    z-index: 2;
}
form .udesk-cem-select,
form .udesk-cem-cascader-picker {
    width: 100%;
}
form .udesk-cem-input-group .udesk-cem-select,
form .udesk-cem-input-group .udesk-cem-cascader-picker {
    width: auto;
}
form :not(.udesk-cem-input-group-wrapper) > .udesk-cem-input-group,
form .udesk-cem-input-group-wrapper {
    position: relative;
    top: -1px;
    display: inline-block;
    vertical-align: middle;
}
.udesk-cem-legacy-form-vertical .udesk-cem-legacy-form-item-label,
.udesk-cem-col-24.udesk-cem-legacy-form-item-label,
.udesk-cem-col-xl-24.udesk-cem-legacy-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
    flex-basis: 100%;
}
.udesk-cem-legacy-form-vertical .udesk-cem-legacy-form-item-label label::after,
.udesk-cem-col-24.udesk-cem-legacy-form-item-label label::after,
.udesk-cem-col-xl-24.udesk-cem-legacy-form-item-label label::after {
    display: none;
}
.udesk-cem-legacy-form-vertical .udesk-cem-legacy-form-item {
    padding-bottom: 8px;
}
.udesk-cem-legacy-form-vertical .udesk-cem-legacy-form-item-control {
    line-height: 1.5715;
}
.udesk-cem-legacy-form-vertical .udesk-cem-legacy-form-explain {
    margin-top: 2px;
    margin-bottom: -5px;
}
.udesk-cem-legacy-form-vertical .udesk-cem-legacy-form-extra {
    margin-top: 2px;
    margin-bottom: -4px;
}
@media (max-width: 575px) {
    .udesk-cem-legacy-form-item-label,
    .udesk-cem-legacy-form-item-control-wrapper {
        display: block;
        width: 100%;
    }
    .udesk-cem-legacy-form-item-label {
        display: block;
        margin: 0;
        padding: 0 0 8px;
        line-height: 1.5715;
        white-space: initial;
        text-align: left;
        flex-basis: 100%;
    }
    .udesk-cem-legacy-form-item-label label::after {
        display: none;
    }
    .udesk-cem-legacy-form-item-control-wrapper {
        flex-basis: 100%;
    }
    .udesk-cem-col-xs-24.udesk-cem-legacy-form-item-label {
        display: block;
        margin: 0;
        padding: 0 0 8px;
        line-height: 1.5715;
        white-space: initial;
        text-align: left;
        flex-basis: 100%;
    }
    .udesk-cem-col-xs-24.udesk-cem-legacy-form-item-label label::after {
        display: none;
    }
}
@media (max-width: 767px) {
    .udesk-cem-col-sm-24.udesk-cem-legacy-form-item-label {
        display: block;
        margin: 0;
        padding: 0 0 8px;
        line-height: 1.5715;
        white-space: initial;
        text-align: left;
        flex-basis: 100%;
    }
    .udesk-cem-col-sm-24.udesk-cem-legacy-form-item-label label::after {
        display: none;
    }
}
@media (max-width: 991px) {
    .udesk-cem-col-md-24.udesk-cem-legacy-form-item-label {
        display: block;
        margin: 0;
        padding: 0 0 8px;
        line-height: 1.5715;
        white-space: initial;
        text-align: left;
        flex-basis: 100%;
    }
    .udesk-cem-col-md-24.udesk-cem-legacy-form-item-label label::after {
        display: none;
    }
}
@media (max-width: 1199px) {
    .udesk-cem-col-lg-24.udesk-cem-legacy-form-item-label {
        display: block;
        margin: 0;
        padding: 0 0 8px;
        line-height: 1.5715;
        white-space: initial;
        text-align: left;
        flex-basis: 100%;
    }
    .udesk-cem-col-lg-24.udesk-cem-legacy-form-item-label label::after {
        display: none;
    }
}
@media (max-width: 1599px) {
    .udesk-cem-col-xl-24.udesk-cem-legacy-form-item-label {
        display: block;
        margin: 0;
        padding: 0 0 8px;
        line-height: 1.5715;
        white-space: initial;
        text-align: left;
        flex-basis: 100%;
    }
    .udesk-cem-col-xl-24.udesk-cem-legacy-form-item-label label::after {
        display: none;
    }
}
.udesk-cem-legacy-form-inline .udesk-cem-legacy-form-item {
    display: inline-block;
    margin-right: 16px;
    margin-bottom: 0;
}
.udesk-cem-legacy-form-inline .udesk-cem-legacy-form-item-with-help {
    margin-bottom: 24px;
}
.udesk-cem-legacy-form-inline .udesk-cem-legacy-form-item > .udesk-cem-legacy-form-item-control-wrapper,
.udesk-cem-legacy-form-inline .udesk-cem-legacy-form-item > .udesk-cem-legacy-form-item-label {
    display: inline-block;
    vertical-align: top;
}
.udesk-cem-legacy-form-inline .udesk-cem-legacy-form-text {
    display: inline-block;
}
.udesk-cem-legacy-form-inline .has-feedback {
    display: inline-block;
}
.has-success.has-feedback .udesk-cem-legacy-form-item-children-icon,
.has-warning.has-feedback .udesk-cem-legacy-form-item-children-icon,
.has-error.has-feedback .udesk-cem-legacy-form-item-children-icon,
.is-validating.has-feedback .udesk-cem-legacy-form-item-children-icon {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 1;
    width: 32px;
    height: 20px;
    margin-top: -10px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    visibility: visible;
    animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    pointer-events: none;
}
.has-success.has-feedback .udesk-cem-legacy-form-item-children-icon svg,
.has-warning.has-feedback .udesk-cem-legacy-form-item-children-icon svg,
.has-error.has-feedback .udesk-cem-legacy-form-item-children-icon svg,
.is-validating.has-feedback .udesk-cem-legacy-form-item-children-icon svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}
.has-success.has-feedback .udesk-cem-legacy-form-item-children-icon {
    color: #52c41a;
    animation-name: diffZoomIn1 !important;
}
.has-warning .udesk-cem-legacy-form-explain,
.has-warning .udesk-cem-legacy-form-split {
    color: #faad14;
}
.has-warning .udesk-cem-input,
.has-warning .udesk-cem-input:hover {
    background-color: #fff;
    border-color: #faad14;
}
.has-warning .udesk-cem-input:focus {
    border-color: #ffc53d;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.has-warning .udesk-cem-input:not([disabled]):hover {
    border-color: #faad14;
}
.has-warning .udesk-cem-calendar-picker-open .udesk-cem-calendar-picker-input {
    border-color: #ffc53d;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.has-warning .udesk-cem-input-affix-wrapper .udesk-cem-input,
.has-warning .udesk-cem-input-affix-wrapper .udesk-cem-input:hover {
    background-color: #fff;
    border-color: #faad14;
}
.has-warning .udesk-cem-input-affix-wrapper .udesk-cem-input:focus {
    border-color: #ffc53d;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.has-warning .udesk-cem-input-affix-wrapper:hover .udesk-cem-input:not(.udesk-cem-input-disabled) {
    border-color: #faad14;
}
.has-warning .udesk-cem-input-prefix {
    color: #faad14;
}
.has-warning .udesk-cem-input-group-addon {
    color: #faad14;
    background-color: #fff;
    border-color: #faad14;
}
.has-warning .has-feedback {
    color: #faad14;
}
.has-warning.has-feedback .udesk-cem-legacy-form-item-children-icon {
    color: #faad14;
    animation-name: diffZoomIn3 !important;
}
.has-warning .udesk-cem-select-selection {
    border-color: #faad14;
}
.has-warning .udesk-cem-select-selection:hover {
    border-color: #faad14;
}
.has-warning .udesk-cem-select-open .udesk-cem-select-selection,
.has-warning .udesk-cem-select-focused .udesk-cem-select-selection {
    border-color: #ffc53d;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.has-warning .udesk-cem-calendar-picker-icon::after,
.has-warning .udesk-cem-time-picker-icon::after,
.has-warning .udesk-cem-picker-icon::after,
.has-warning .udesk-cem-select-arrow,
.has-warning .udesk-cem-cascader-picker-arrow {
    color: #faad14;
}
.has-warning .udesk-cem-input-number,
.has-warning .udesk-cem-time-picker-input {
    border-color: #faad14;
}
.has-warning .udesk-cem-input-number-focused,
.has-warning .udesk-cem-time-picker-input-focused,
.has-warning .udesk-cem-input-number:focus,
.has-warning .udesk-cem-time-picker-input:focus {
    border-color: #ffc53d;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.has-warning .udesk-cem-input-number:not([disabled]):hover,
.has-warning .udesk-cem-time-picker-input:not([disabled]):hover {
    border-color: #faad14;
}
.has-warning .udesk-cem-cascader-picker:focus .udesk-cem-cascader-input {
    border-color: #ffc53d;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.has-warning .udesk-cem-cascader-picker:hover .udesk-cem-cascader-input {
    border-color: #faad14;
}
.has-warning .udesk-cem-input-affix-wrapper {
    border-color: #faad14;
}
.has-warning .udesk-cem-input-affix-wrapper-focused {
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.has-warning .udesk-cem-input-affix-wrapper .udesk-cem-input:focus {
    box-shadow: none;
}
.has-error .udesk-cem-legacy-form-explain,
.has-error .udesk-cem-legacy-form-split {
    color: #ff4d4f;
}
.has-error .udesk-cem-input,
.has-error .udesk-cem-input:hover {
    background-color: #fff;
    border-color: #ff4d4f;
}
.has-error .udesk-cem-input:focus {
    border-color: #ff7875;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}
.has-error .udesk-cem-input:not([disabled]):hover {
    border-color: #ff4d4f;
}
.has-error .udesk-cem-calendar-picker-open .udesk-cem-calendar-picker-input {
    border-color: #ff7875;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}
.has-error .udesk-cem-input-affix-wrapper .udesk-cem-input,
.has-error .udesk-cem-input-affix-wrapper .udesk-cem-input:hover {
    background-color: #fff;
    border-color: #ff4d4f;
}
.has-error .udesk-cem-input-affix-wrapper .udesk-cem-input:focus {
    border-color: #ff7875;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}
.has-error .udesk-cem-input-affix-wrapper:hover .udesk-cem-input:not(.udesk-cem-input-disabled) {
    border-color: #ff4d4f;
}
.has-error .udesk-cem-input-prefix {
    color: #ff4d4f;
}
.has-error .udesk-cem-input-group-addon {
    color: #ff4d4f;
    background-color: #fff;
    border-color: #ff4d4f;
}
.has-error .has-feedback {
    color: #ff4d4f;
}
.has-error.has-feedback .udesk-cem-legacy-form-item-children-icon {
    color: #ff4d4f;
    animation-name: diffZoomIn2 !important;
}
.has-error .udesk-cem-select-selection {
    border-color: #ff4d4f;
}
.has-error .udesk-cem-select-selection:hover {
    border-color: #ff4d4f;
}
.has-error .udesk-cem-select-open .udesk-cem-select-selection,
.has-error .udesk-cem-select-focused .udesk-cem-select-selection {
    border-color: #ff7875;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}
.has-error .udesk-cem-select.udesk-cem-select-auto-complete .udesk-cem-input:focus {
    border-color: #ff4d4f;
}
.has-error .udesk-cem-input-group-addon .udesk-cem-select-selection {
    border-color: transparent;
    box-shadow: none;
}
.has-error .udesk-cem-calendar-picker-icon::after,
.has-error .udesk-cem-time-picker-icon::after,
.has-error .udesk-cem-picker-icon::after,
.has-error .udesk-cem-select-arrow,
.has-error .udesk-cem-cascader-picker-arrow {
    color: #ff4d4f;
}
.has-error .udesk-cem-input-number,
.has-error .udesk-cem-time-picker-input {
    border-color: #ff4d4f;
}
.has-error .udesk-cem-input-number-focused,
.has-error .udesk-cem-time-picker-input-focused,
.has-error .udesk-cem-input-number:focus,
.has-error .udesk-cem-time-picker-input:focus {
    border-color: #ff7875;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}
.has-error .udesk-cem-input-number:not([disabled]):hover,
.has-error .udesk-cem-time-picker-input:not([disabled]):hover {
    border-color: #ff4d4f;
}
.has-error .udesk-cem-mention-wrapper .udesk-cem-mention-editor,
.has-error .udesk-cem-mention-wrapper .udesk-cem-mention-editor:not([disabled]):hover {
    border-color: #ff4d4f;
}
.has-error .udesk-cem-mention-wrapper.udesk-cem-mention-active:not([disabled]) .udesk-cem-mention-editor,
.has-error .udesk-cem-mention-wrapper .udesk-cem-mention-editor:not([disabled]):focus {
    border-color: #ff7875;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}
.has-error .udesk-cem-cascader-picker:focus .udesk-cem-cascader-input {
    border-color: #ff7875;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}
.has-error .udesk-cem-cascader-picker:hover .udesk-cem-cascader-input {
    border-color: #ff4d4f;
}
.has-error .udesk-cem-transfer-list {
    border-color: #ff4d4f;
}
.has-error .udesk-cem-transfer-list-search:not([disabled]) {
    border-color: #d9d9d9;
}
.has-error .udesk-cem-transfer-list-search:not([disabled]):hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
}
.has-error .udesk-cem-transfer-list-search:not([disabled]):focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.has-error .udesk-cem-input-affix-wrapper {
    border-color: #ff4d4f;
}
.has-error .udesk-cem-input-affix-wrapper-focused {
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}
.has-error .udesk-cem-input-affix-wrapper .udesk-cem-input:focus {
    box-shadow: none;
}
.is-validating.has-feedback .udesk-cem-legacy-form-item-children-icon {
    display: inline-block;
    color: #1890ff;
}
.udesk-cem-advanced-search-form .udesk-cem-legacy-form-item {
    margin-bottom: 24px;
}
.udesk-cem-advanced-search-form .udesk-cem-legacy-form-item-with-help {
    margin-bottom: 4px;
}
.show-help-enter,
.show-help-appear {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-play-state: paused;
}
.show-help-leave {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-play-state: paused;
}
.show-help-enter.show-help-enter-active,
.show-help-appear.show-help-appear-active {
    animation-name: antShowHelpIn;
    animation-play-state: running;
}
.show-help-leave.show-help-leave-active {
    animation-name: antShowHelpOut;
    animation-play-state: running;
    pointer-events: none;
}
.show-help-enter,
.show-help-appear {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
.show-help-leave {
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
@keyframes antShowHelpIn {
    0% {
        transform: translateY(-5px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes antShowHelpOut {
    to {
        transform: translateY(-5px);
        opacity: 0;
    }
}
@keyframes diffZoomIn1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes diffZoomIn2 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes diffZoomIn3 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
.udesk-cem-mention-wrapper {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
}
.udesk-cem-mention-wrapper .udesk-cem-mention-editor {
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
    display: block;
    height: auto;
    min-height: 32px;
    padding: 0;
    line-height: 1.5715;
}
.udesk-cem-mention-wrapper .udesk-cem-mention-editor::-moz-placeholder {
    opacity: 1;
}
.udesk-cem-mention-wrapper .udesk-cem-mention-editor::placeholder {
    color: #bfbfbf;
}
.udesk-cem-mention-wrapper .udesk-cem-mention-editor:placeholder-shown {
    text-overflow: ellipsis;
}
.udesk-cem-mention-wrapper .udesk-cem-mention-editor:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
}
.udesk-cem-mention-wrapper .udesk-cem-mention-editor:focus,
.udesk-cem-mention-wrapper .udesk-cem-mention-editor-focused {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.udesk-cem-mention-wrapper .udesk-cem-mention-editor-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
}
.udesk-cem-mention-wrapper .udesk-cem-mention-editor-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
}
.udesk-cem-mention-wrapper .udesk-cem-mention-editor[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
}
.udesk-cem-mention-wrapper .udesk-cem-mention-editor[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
}
textarea.udesk-cem-mention-wrapper .udesk-cem-mention-editor {
    max-width: 100%;
    height: auto;
    min-height: 32px;
    line-height: 1.5715;
    vertical-align: bottom;
    transition: all 0.3s, height 0s;
}
.udesk-cem-mention-wrapper .udesk-cem-mention-editor-lg {
    padding: 6.5px 11px;
    font-size: 16px;
}
.udesk-cem-mention-wrapper .udesk-cem-mention-editor-sm {
    padding: 0px 7px;
}
.udesk-cem-mention-wrapper .udesk-cem-mention-editor-wrapper {
    height: auto;
    overflow-y: auto;
}
.udesk-cem-mention-wrapper.udesk-cem-mention-active:not(.disabled) .udesk-cem-mention-editor {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.udesk-cem-mention-wrapper.disabled .udesk-cem-mention-editor {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
}
.udesk-cem-mention-wrapper.disabled .udesk-cem-mention-editor:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
}
.udesk-cem-mention-wrapper .public-DraftEditorPlaceholder-root {
    position: absolute;
    pointer-events: none;
}
.udesk-cem-mention-wrapper .public-DraftEditorPlaceholder-root .public-DraftEditorPlaceholder-inner {
    height: auto;
    padding: 5px 11px;
    color: #bfbfbf;
    white-space: pre-wrap;
    word-wrap: break-word;
    outline: none;
    opacity: 1;
}
.udesk-cem-mention-wrapper .DraftEditor-editorContainer .public-DraftEditor-content {
    height: auto;
    padding: 5px 11px;
}
.udesk-cem-mention-dropdown {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 1050;
    min-width: 120px;
    max-height: 250px;
    margin-top: 1.5em;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #fff;
    border-radius: 2px;
    outline: none;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.udesk-cem-mention-dropdown-placement-top {
    margin-top: -0.1em;
}
.udesk-cem-mention-dropdown-notfound.udesk-cem-mention-dropdown-item {
    color: rgba(0, 0, 0, 0.25);
}
.udesk-cem-mention-dropdown-notfound.udesk-cem-mention-dropdown-item .anticon-loading {
    display: block;
    color: #1890ff;
    text-align: center;
}
.udesk-cem-mention-dropdown-item {
    position: relative;
    display: block;
    padding: 5px 12px;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.65);
    font-weight: normal;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    transition: background 0.3s;
}
.udesk-cem-mention-dropdown-item:hover {
    background-color: #f5f5f5;
}
.udesk-cem-mention-dropdown-item.focus,
.udesk-cem-mention-dropdown-item-active {
    background-color: #e6f7ff;
}
.udesk-cem-mention-dropdown-item-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
}
.udesk-cem-mention-dropdown-item-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: #fff;
    cursor: not-allowed;
}
.udesk-cem-mention-dropdown-item-selected,
.udesk-cem-mention-dropdown-item-selected:hover {
    color: rgba(0, 0, 0, 0.65);
    font-weight: bold;
    background-color: #f5f5f5;
}
.udesk-cem-mention-dropdown-item-divider {
    height: 1px;
    margin: 1px 0;
    overflow: hidden;
    line-height: 0;
    background-color: #f0f0f0;
}
