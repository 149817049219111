@import './consts';
.udesk-cem-input {
    &:hover {
        border-color: $primary-color !important;
    }
    &:focus {
        box-shadow: 0 0 0 2px fade-out($primary-color, 0.8);
    }
    &:visited {
        border-color: $primary-color !important;
    }
}
