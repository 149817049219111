@import './consts';
.applyOrganizationIds-select {
    max-height: 264px !important;
}
.udesk-cem-tree {
    > li {
        .udesk-cem-tree-node-content-wrapper {
            &:hover {
                background: fade-out($primary-color, 0.95);
            }
        }
        .udesk-cem-tree-node-content-wrapper.udesk-cem-tree-node-selected {
            background: fade-out($primary-color, 0.95);
        }
    }
}
.udesk-cem-select-tree {
    > li {
        .udesk-cem-select-tree-node-content-wrapper {
            &:hover {
                background: fade-out($primary-color, 0.95);
            }
        }
        .udesk-cem-select-tree-node-content-wrapper.udesk-cem-select-tree-node-selected {
            background: fade-out($primary-color, 0.95);
        }
        .udesk-cem-select-tree-node-selected {
            background: fade-out($primary-color, 0.95) !important;
        }
    }
}
