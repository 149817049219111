@mixin clearfix() {
    *zoom: 1;
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

@mixin ellipsis() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin x-word-break() {
    word-wrap: break-word;
    word-break: break-all;
}

@mixin box-shadow($params: 1px 1px 3px #EBEBEB) {
    -webkit-box-shadow: $params;
    box-shadow: $params;
}

@mixin border-radius($params: 5px) {
    -webkit-border-radius: $params;
    border-radius: $params;
}

@mixin center-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

@mixin scrollbarY() {
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar-thumb {
        background: rgba(45, 62, 79, 0.3);
        border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 2px;
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 6px;
        height: 13px;
    }
}

.scrollbar-y {
    @include scrollbarY;
}

.scrollbar,
.scrollbar-x,
.scrollbar-hover {
    overflow: auto;

    &::-webkit-scrollbar-thumb {
        background: rgba(45, 62, 79, 0.3);
        border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 2px;
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 6px;
        height: 13px;
    }
}
.clearfix {
    @include clearfix;
}

.ellipsis {
    @include ellipsis;
}

.word-break {
    @include x-word-break;
}

.border-radius {
    @include border-radius;
}

.box-shadow {
    @include box-shadow;
}

.center-box {
    @include center-box;
}

.pull-left {
    float: left!important;
}

.pull-right {
    float: right!important;
}

.cursor-pointer{
    cursor:pointer;
}