@import './consts';
.udesk-cem-select {
    .udesk-cem-select-selection {
        &:hover {
            border-color: $primary-color !important;
        }
        &:focus {
            border-color: $primary-color !important;
            box-shadow: 0 0 0 2px fade-out($primary-color, 0.8);
        }
        &:active {
            border-color: $primary-color !important;
        }
        &:visited {
            border-color: $primary-color !important;
        }
    }
}
.udesk-cem-select-focused {
    .udesk-cem-select-selection {
        border-color: $primary-color !important;
    }
}
.udesk-cem-select-dropdown {
    .udesk-cem-select-dropdown-menu {
        .udesk-cem-select-dropdown-menu-item-active:not(.udesk-cem-select-dropdown-menu-item-disabled) {
            background-color: fade-out($primary-color, 0.95);
        }
        .udesk-cem-select-dropdown-menu-item {
            &:hover {
                background-color: fade-out($primary-color, 0.95);
            }
        }
        .udesk-cem-select-dropdown-menu-item-selected {
            background-color: fade-out($primary-color, 0.95);
        }
    }
}
