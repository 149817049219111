@import './consts';
.udesk-cem-checkbox {
    &:hover {
        .udesk-cem-checkbox-inner {
            border-color: $primary-color;
        }
    }
}
.udesk-cem-checkbox-indeterminate {
    .udesk-cem-checkbox-inner {
        &:after {
            background-color: $primary-color;
        }
    }
}
.udesk-cem-checkbox-checked {
    .udesk-cem-checkbox-inner {
        background-color: $primary-color;
        border-color: $primary-color;
        &:after {
            background-color: $primary-color;
        }
    }
}
.udesk-cem-checkbox-disabled {
    .udesk-cem-checkbox-inner {
        background-color: #f5f5f5 !important;
        &:after {
            background-color: #f5f5f5;
        }
    }
}
