@import './consts';
.udesk-cem-pagination {
    .udesk-cem-pagination-prev,
    .udesk-cem-pagination-next {
        .udesk-cem-pagination-item-link {
            &:hover {
                border: solid 1px $primary-color;
                color: $primary-color;
            }
            &:active {
                border: solid 1px $primary-color;
                color: $primary-color;
            }
        }
    }
    .udesk-cem-pagination-item {
        &:hover {
            border: solid 1px $primary-color;
            > a {
                color: $primary-color;
            }
        }
    }
    .udesk-cem-pagination-item-active {
        border: solid 1px $primary-color;
        > a {
            color: $primary-color;
        }
    }
    .udesk-cem-pagination-options-quick-jumper {
        > input {
            &:hover {
                border: solid 1px $primary-color;
            }
            &:focus {
                border: solid 1px $primary-color;
            }
        }
    }
}
